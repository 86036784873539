import {action, computed, configure, makeObservable, observable} from "mobx";
import axios from "axios";
import moment from "moment";
import {isLoading, loading, RequestState} from "../Shared/StateHelper";
import {toast} from "react-toastify";
import socket from "../Shared/socket";
import {displayMedocError} from "../Shared/Enums/ErrorCodes";
import MesureStore from "../Appointments/Mesures/MesureStore";
import {Act} from "../Shared/Enums/Enums";


configure({enforceActions: 'never'}) // done during the migration
export const CVState = {
    INITIAL: "INITIAL", // step 0
    DISPLAY_PATIENT: "DISPLAY_NEW_PATIENT", // step 3
    SELECT_PATIENT: "SELECT_PATIENT", // step 2
    DISPLAY_MULTIPLE_PATIENTS: "DISPLAY_MULTIPLE_PATIENTS", // step 1
    EDIT_FSE: "EDIT_FSE",
}

class CVStore {
    @observable patient = {};
    @observable appointment_id = undefined;
    @observable state;
    @observable previousStates;
    @observable patientList;
    @observable selectedPatient;
    @observable currentCVMeta;
    @observable patientFromSocket;
    @observable retrievedData;
    @observable adriRights;
    @observable fse;
    @observable cvRequestState
    @observable adriRequestState
    @observable fseRequestState
    @observable delayedAppointments = [];
    @observable prescriptors = [];
    @observable selectedPrescriptor = null;
    @observable prescriptionDate = moment().format('YYYY-MM-DD');

    /////////////////////////////// REFONTE

    constructor() {
        this.init()
        makeObservable(this)
    }

    init(actes = null) {
        this.state = CVState.INITIAL
        this.previousStates = []
        this.patientList = []
        this.patient = {}
        this.selectedPatient = {}
        this.patientFromSocket = {}
        this.retrievedData = {}
        this.adriRights = {}
        this.fse = {}
        this.cvRequestState = RequestState.IDLE
        this.adriRequestState = RequestState.IDLE
        this.fseRequestState = RequestState.IDLE
        this.currentCVMeta = {}
    }

    @action reset() {
        this.init()
    }

    @action subscribe() {
        console.debug("CVStore subscribed")
        socket.on("cvInputResponse", (resp) => this.handleCVInputResponse(resp))
        socket.on("adriRightsResponse", (resp) => this.handleAdriRightsResponse(resp))
        socket.on("fseCreationResponse", (resp) => this.handleFSEResponse(resp))
    }

    @action unsubscribe() {
        socket.off("cvInputResponse")
        socket.off("adriRightsResponse")
        socket.off("fseCreationResponse")
    }

    @action initFse(orthoptist, acts, patient, appointment_id) {
        this.patient = patient
        this.fse = Object.assign({}, this.fse, {
            invoiceId: appointment_id, // The id to identify the appt when the NOEMI response is fetched
            rpps: orthoptist.rpps, // les 11 chiffres du haut sur la carte CPS (sans les chiffres avant l'espace)
            adeli: orthoptist.numero_facturation, // les 9 chiffres du bas
            patient_ssn: patient.num_carte_vitale,
            acts: []
        })
        this.resolveMultipleActs(acts)
    }

    // RESPONSE HANDLERS
    handleCVInputResponse(resp) {
        console.debug("CV INPUT RESPONSE RECEIVED")
        if (resp.status === "success") {
            console.debug("success", resp.data, resp.meta);
            this.cvRequestState = RequestState.IDLE
            this.currentCVMeta.regime_code = resp.meta.sCodeRegime
            this.currentCVMeta.fund_code = resp.meta.sCodeCaisse
            this.currentCVMeta.centre_code = resp.meta.sCodeCentre
            if (resp.data.length > 0) {
                this.patientList = resp.data
                this.selectedPatient = this.patientList[0]
                this.selectedPatient.nom = this.patientList[0].sNomUsuelPatient
                this.selectedPatient.prenom = this.patientList[0].sPrenomPatient
                this.selectedPatient.birthday = this.patientList[0].dDateDeNaissance
                this.selectedPatient.num_carte_vitale = this.patientList[0].sNumeroSecuriteSocialePatient?.replace(/\s/g, '')
                this.selectedPatient.birth_rank = this.patientList[0].nRangNaissance
                this.setState(CVState.DISPLAY_MULTIPLE_PATIENTS)
            } else {
                this.cvRequestState = RequestState.ERROR
            }
        } else {
            console.debug("Error on CV retrieving with error: ", displayMedocError(resp.errorCode));
            this.cvRequestState = RequestState.ERROR
        }
    }

    handleAdriRightsResponse(resp) {
        console.debug("ADRI RIGHTS RESPONSE RECEIVED")
        if (resp.status === "success") {
            console.debug("success", resp.data, resp.meta)
            if (resp.data) {
                this.adriRights = Object.assign({}, this.adriRights, {
                    couv_obligatoire: resp.data.sCouvAMO,
                    couv_obligatoire_expiration: moment(resp.data.sDateFinAMO, "YYYYMMDD").format("DD/MM/YYYY").toString(),
                    couv_complementaire: resp.data.sCouvAMC,
                    couv_complementaire_expiration: moment(resp.data.sDateFinAMC, "YYYYMMDD").format("DD/MM/YYYY").toString(),
                    // MT = Médecin traitant
                    mtCode: resp.data.nCodeDeclarationMT,
                })
                this.patientFromSocket.num_carte_vitale = resp.data.sNumeroSecuriteSocialePatient
                this.selectedPatient.num_carte_vitale = resp.data.sNumeroSecuriteSocialePatient
                this.adriRequestState = RequestState.SUCCESS
            }
        } else {
            console.debug("Error occured while retrieving ADRI rights with error: ", displayMedocError(resp.errorCode));
            this.adriRequestState = RequestState.ERROR
        }

    }

    handleFSEResponse(resp) {
        console.debug("FSE RESPONSE RECEIVED")
        if (resp.status === "success") {
            console.debug("success", resp.data, resp.meta)
            if (resp.data.bFactureCreee) {
                toast.success("Feuille de soin électronique envoyée avec succès")
                this.fseRequestState = RequestState.SUCCESS
                axios.patch('/orthoptistes/chained_acts/paid', {appointment_id: this.appointment_id}).then()
                const medoc_id = resp.data.sNumeroFSE
                axios.patch("/api/appointements/medoc_id", {
                    medoc_id: medoc_id,
                    appointment_id: this.appointment_id
                }).then()
                MesureStore.appointment_teletransmission_status = "PENDING"
                console.debug("FSE created with id: ", medoc_id)
            } else {
                console.debug("failed", resp.data)
                toast.info("Création de feuille de soin abandonnée")
                this.fseRequestState = RequestState.ERROR
            }
        } else {
            console.debug("Error occured while creating FSE with error: ", displayMedocError(resp.errorCode));
            if (resp.errorCode === 500) toast.info("Création de feuille de soin abandonnée")
            this.fseRequestState = RequestState.ERROR
        }
    }

    // SOCKET REQUESTS
    @action
    connect(uid) {
        socket.emit("machineID", {machine: uid});
    }

    @action
    disconnect() {
        socket.disconnect()
    }

    @action
    askForCVInput(uid) {
        if (isLoading(this.cvRequestState)) return

        if (uid !== null) {
            console.debug("Requesting CV reading")
            socket.emit("cvInputRequest", {machine: uid});
            this.cvRequestState = loading(this.cvRequestState)
            setTimeout(() => {
                if (isLoading(this.cvRequestState)) this.cvRequestState = RequestState.ERROR
            }, 20000) // 20 seconds timeout
        } else {
            this.cvRequestState = RequestState.ERROR
        }
    }

    @action
    adriRightsRequest(uid) {
        if (isLoading(this.adriRequestState)) return
        const patient = this.selectedPatient

        if (uid !== null) {
            const data = {
                patient_firstname: patient.prenom.toUpperCase(),
                patient_lastname: patient.nom.toUpperCase(),
                patient_birthday: Helper.formatDate(patient.birthday, "DDMMYYYY"),
                patient_ssn: patient.num_carte_vitale,
                patient_rank: patient.birth_rank,
                patient_regime_code: this.currentCVMeta.regime_code,
                patient_fund_code: this.currentCVMeta.fund_code,
                patient_centre_code: this.currentCVMeta.centre_code
            }
            console.debug("Requesting ADRI rights with data : ", data)
            socket.emit("adriRightsRequest",
                {
                    machine: uid,
                    data: data
                });
            this.adriRequestState = loading(this.adriRequestState)
            setTimeout(() => {
                if (isLoading(this.adriRequestState)) this.adriRequestState = RequestState.ERROR
            }, 1000 * 60) // 1 minute timeout
        } else {
            console.debug("No uid provided for ADRI rights request")
            this.adriRequestState = RequestState.ERROR
        }
    }

    @action
    fseCreationRequest(uid, delayedAppointments = []) {
        const appointmentDates = [moment(), ...delayedAppointments.map(appt => moment(appt.created_at))]
        if (isLoading(this.fseRequestState)) return
        const data = {
            invoiceId: this.fse.invoiceId,
            // time: moment().format().split("+")[0], // "2022-05-03T15:31:27.452",
            time: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"), // "2022-05-03T15:31:27.452",
            comment: "Feuille de soin générée par Temeoo",
            rpps: this.fse.adeli, // MedOc takes Adeli on Rpps slot in case of orthoptist (which has no rpps)
            adeli: this.fse.adeli,
            patient_firstname: this.patient.prenom,
            patient_lastname: this.patient.nom,
            patient_ssn: this.patient.num_carte_vitale,
            patient_birthday: Helper.formatDate(this.patient.birthday, "YYYYMMDD"),
            patient_rank: this.patient?.birth_rank || 1,
            acts: this.fse.acts.map((act, index) => {
                return appointmentDates.reduce((acc, date) => {
                        acc = acc.concat({
                            type: "NGAP",
                            action: "creation",
                            isValid: true,
                            isBillable: act.prix !== 0,
                            isFree: act.prix === 0,
                            emiterId: "7", // TODO but not mandatory so what's the point ?
                            actCode: act.actCode || "",
                            activityCode: act.activityCode || "",
                            phaseCode: act.phaseCode || "",
                            associationCode: (index + 1).toString(),
                            price: act.prix * 100,
                            // time: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"), // "2022-05-03T15:31:27.452",
                            time: date.format("YYYY-MM-DDTHH:mm:ss.SSS"), // "2022-05-03T15:31:27.452",
                            coefficient: act.coefficient,
                            quantity: 1
                        })
                        return acc
                    }
                    , [])
            }).flat(),
            // prescriptor
            scor: this.selectedPrescriptor ? {
                prescriptor_adeli: this.selectedPrescriptor.adeli,
                prescriptor_rpps: this.selectedPrescriptor.rpps,
                prescriptor_specialty_code: this.selectedPrescriptor.specialty_code,
                prescription_date: moment(this.prescriptionDate).format('YYYY-MM-DD'), //"2022-05-03",
                prescriptor_firstname: this.selectedPrescriptor.firstname.toUpperCase(),
                prescriptor_lastname: this.selectedPrescriptor.lastname.toUpperCase(),
                type: this.selectedPrescriptor.practitioner_type, // 0 = Libéral, 1 =  Dentiste, 2 = Salarié, 3 = Bénévole
                prescriptor_structure_number: "", // empty string according to Med'OC
            } : undefined
        }
        console.debug("Requesting FSE creation with data : ", data)
        if (uid !== null) {
            socket.emit("fseCreationRequest",
                {
                    machine: uid,
                    data: data
                });
            this.fseRequestState = loading(this.fseRequestState)
            setTimeout(() => {
                if (isLoading(this.fseRequestState)) this.fseRequestState = RequestState.ERROR
            }, 5000)
        } else {
            this.fseRequestState = RequestState.ERROR
        }
    }

    @action
    udpatePatient() {
        const ssn = this.selectedPatient.sNumeroSecuriteSocialePatient
        const rank = parseInt(this.selectedPatient.nRangNaissance)
        const regime_code = this.currentCVMeta.regime_code
        const fund_code = this.currentCVMeta.fund_code
        const centre_code = this.currentCVMeta.centre_code

        axios.post('/api/v3/patients/update', {
            num_carte_vitale: ssn?.replace(/\s/g, ''),
            birth_rank: rank,
            id: this.patient.id,
            regime_code: regime_code,
            fund_code: fund_code,
            centre_code: centre_code
        }).then((data) => {
            this.patient.num_carte_vitale = data.data.patient.num_carte_vitale
            this.patient.birth_rank = data.data.patient.birth_rank
            this.patient.regime_code = data.data.patient.regime_code
            this.patient.fund_code = data.data.patient.fund_code
            this.patient.centre_code = data.data.patient.centre_code
            this.patient.nom = this.selectedPatient.nom
            this.patient.prenom = this.selectedPatient.prenom
            this.setState(CVState.DISPLAY_PATIENT)
        })
    }

    @action
    resolveMultipleActs(acts) {
        const ids = acts.reduce((acc, act) => {
            if (act.multipleActs === null) acc.push(act.id)
            else acc = acc.concat(act.multipleActs)
            return acc
        }, [])
        axios.get("/api/acts", {params: {ids: ids}}).then((resp) => {
            this.fse = Object.assign({}, this.fse, {acts: resp.data})
        })
    }

    triggerDegradedMode = (patient) => {
        if (!isLoading(this.cvRequestState)) {
            this.patientList = [patient]
            this.selectedPatient = patient
            this.currentCVMeta.regime_code = patient.regime_code
            this.currentCVMeta.fund_code = patient.fund_code
            this.currentCVMeta.centre_code = patient.centre_code
            this.setState(CVState.DISPLAY_PATIENT)
        }
    }

    // WHATEVER
    @computed get hasCV() {
        return this.patient.num_carte_vitale && this.patient.num_carte_vitale.length > 0
    }

    @action
    newPatient() {
        this.selectedPatient = this.patientFromSocket
        this.setState(CVState.DISPLAY_PATIENT)
    }

    @action
    selectPatient(patient) {
        this.selectedPatient = patient
        this.selectedPatient.nom = patient.sNomUsuelPatient
        this.selectedPatient.prenom = patient.sPrenomPatient
        this.selectedPatient.birthday = patient.dDateDeNaissance
        this.selectedPatient.num_carte_vitale = patient.sNumeroSecuriteSocialePatient?.replace(/\s/g, '')

    }

    @action
    back() {
        if (this.previousStates.length > 0) this.state = this.previousStates.pop()
    }

    setState(state) {
        if (![CVState.ERROR, CVState.LOADING].includes(this.state)) this.previousStates.push(this.state)
        this.state = state
    }

    /////////////////////// REFONTE

    @action
    updatePatientV2(patient, meta) {
        const ssn = patient.sNumeroSecuriteSocialePatient
        const rank = parseInt(patient.nRangNaissance)
        const regime_code = meta.sCodeRegime
        const fund_code = meta.sCodeCaisse
        const centre_code = meta.sCodeCentre
        const birth_date = patient.dDateDeNaissance
        const firstname = patient.sPrenomPatient
        const lastname = patient.sNomUsuelPatient

        axios.post('/api/v3/patients/update', {
            id: this.patient.id,
            nom: lastname,
            prenom: firstname,
            num_carte_vitale: ssn?.replace(/\s/g, ''),
            birth_rank: rank,
            regime_code: regime_code,
            fund_code: fund_code,
            centre_code: centre_code,
            birthday: birth_date
        }).then((data) => {
            this.patient.num_carte_vitale = data.data.patient.num_carte_vitale
            this.patient.birth_rank = data.data.patient.birth_rank
            this.patient.regime_code = data.data.patient.regime_code
            this.patient.fund_code = data.data.patient.fund_code
            this.patient.centre_code = data.data.patient.centre_code
            this.patient.nom = data.data.patient.nom
            this.patient.prenom = data.data.patient.prenom
            this.patient.birthday = data.data.patient.birthday
            MesureStore.patient = this.patient
        })
    }

    @computed get canScorBeSent() {
        const has_scor = MesureStore.selectedChain ? MesureStore.selectedChain?.parent_prescription_url : true
        const has_acts = this.fse?.acts && this.fse.acts.length > 0
        return this.selectedPrescriptor && this.prescriptionDate && has_acts && has_scor
    }

    initPrescriptors = () => {
        this.getPrescriptors()
        this.getPrescriptionDate()
    }

    getPrescriptors = () => {
        const appointment_id = MesureStore.selectedChain?.parent_appointment_id ?? MesureStore.appointement_id
        return axios.get("/prescripteurs", {params: {appointment_id: appointment_id}}).then(res => {
            const prescriptors = res.data.prescriptors.filter(prescriptor => prescriptor.firstname !== "" && prescriptor.lastname !== "");
            this.prescriptors = prescriptors
            const selected_prescriptor_id = res.data.selected_prescriptor_id;
            if (selected_prescriptor_id) {
                this.selectedPrescriptor = prescriptors.find(prescriptor => prescriptor.id === selected_prescriptor_id)
            } else if (MesureStore.selected_act.titre === Act.RNM) {
                const analyst_prescriptor_id = res.data.analyst_prescriptor_id;
                this.selectedPrescriptor = prescriptors.find(prescriptor => prescriptor.id === analyst_prescriptor_id)
            }
        }).catch(err => {
        })
    }

    getPrescriptionDate = () => {
        const appointment_id = MesureStore.selectedChain?.parent_appointment_id ?? MesureStore.appointement_id
        return axios.get("/prescripteurs/prescription_date", {params: {appointment_id: appointment_id}})
            .then(res => {
                this.prescriptionDate = res.data.prescription_date ? moment(res.data.prescription_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            })
    }
}

let cvStore = new CVStore();
export default cvStore