import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {t} from '../../I18n/i18n'
import moment from 'moment'
import PrescriptorStore from "../PrescriptorStore";
import {observer} from "mobx-react";
import Pagination from "../../Shared/Pagination";
import useDebounceV2 from "../../Hooks/useDebounceV2";
import {construct_filter, Filter} from "../../Shared/FilterHelper";

const PatientList = observer(() => {
    const nav = useNavigate()
    const [search, setSearch] = useState(null)
    const debouncedGetPatients = useDebounceV2(PrescriptorStore.getPatients, 500)

    useEffect(() => {
        if (search === null) return
        debouncedGetPatients(1, {nom_prenom_email: {[Filter.CONTAINS_ALL_CASE_INSENSITIVE]: search.split(" ")}})
    }, [search])

    const renderSearchBar = () => {
        return <div className="input-field outlined">
                <input type="text"
                       onChange={e => setSearch(e.target.value)}
                       autoFocus style={{height: 40}}
                       placeholder={t('PATIENT_SEARCH.SEARCH')}
                       data-cy="SearchBarPatient"/>
                {/*<label htmlFor="q">{t('PATIENT_SEARCH.SEARCH')}</label>*/}
            </div>

    }

    const renderPatientList = () => {
        return <div className="container">
            {PrescriptorStore.patients.length > 0 ? (
                <table className="temeoo-table">
                    <tbody>
                    {PrescriptorStore.patients.map((patient, index) => (
                        <tr key={index}>
                            <td className="center">{patient.nom + ' ' + patient.prenom}</td>
                            <td className="center">{moment(patient.birthday).format('DD/MM/YYYY')}</td>
                            <td className="center">{patient.telephone}</td>
                            <td className="center">{patient.email}</td>
                            <td className="center">
                                <a className="btn-flat truncate"
                                   onClick={() => nav(`/patient/${patient.id}`)}>
                                    <i className="material-icons left">account_circle</i>
                                    Accéder aux détails du patient
                                </a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            ) : (
                <p className="center">Aucun patient à afficher</p>
            )}
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10, padding: 20}}>
        <h3 className="center" style={{margin: 0}}>Mes patients</h3>
        <div className="container" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            {renderSearchBar()}
            <Pagination count={PrescriptorStore.patient_count} limit={10}
                        onChange={(page) => PrescriptorStore.getPatients(page)}/>
        </div>
        {/*<div>nb result: {PrescriptorStore.patient_count}</div>*/}
        {renderPatientList()}

    </div>

})

export default PatientList