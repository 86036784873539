import React, {useState} from 'react'
import {observer} from "mobx-react";
import AddPatient from "../AddPatient";
import {useNavigate} from "react-router-dom";
import EditPatient from "../EditPatient";

const _ = require('lodash')
const ListPatients = observer(() => {
    const nav = useNavigate();
    const [onEditPatient, setOnEditPatient] = useState(undefined)

    const renderPatient = (patient) => {
        const hasReport = !_.isEmpty(patient.report)
        return <tr className="highlighted">
            <td>{Helper.formatName(patient.firstname, patient.lastname)}</td>
            <td>Né(e)
                le {patient.birthday} ({Helper.dateToAge(patient.birthday)} ans)
            </td>
            <td>{Helper.formatSsn(patient.ssn)}</td>
            <td>{patient.email}</td>
            <td>{patient.telephone}</td>
            <td>
                {Helper.formatPatientCodes(patient.regime_code, patient.fund_code, patient.centre_code)}
            </td>
            <td>{patient.health_insurance}</td>
            <td style={{width: 230}}>
                <div style={{display: "flex", justifyContent: "right"}}>
                    <a className={`btn-small ${hasReport ? "green" : "blue"} darken-2 z-depth-0 truncate`}
                       style={{marginLeft: "auto"}}
                       onClick={() => nav(`/appointment/${patient.appointment_id}`)}>
                        <i className="material-icons left">{hasReport ? "done" : "description"}</i>
                        Pré-remplir
                    </a>
                    <a className="btn-small orange darken-4 z-depth-0 truncate"
                       onClick={() => setOnEditPatient(patient)}>
                        <i className="material-icons">edit</i>
                    </a>
                    <a className="btn-small red darken-2 z-depth-0 truncate"
                       onClick={() => MedicalEventStore.removePatient(patient)}>
                        <i className="material-icons">delete</i>
                    </a>
                </div>
            </td>
        </tr>
    }

    const renderPatients = () => {
        if (_.isEmpty(MedicalEventStore.patients)) {
            return <div className="center-align" style={{paddingTop: 50}}>
            <h5>Aucun patient n'est affecté à l'évènement, rajoutez-en ci-dessus</h5>
            </div>
        }
        return <table className="temeoo-table">
            <thead>
            <tr>
                <th>Nom</th>
                <th>Date de naissance</th>
                <th>Numéro de sécurité sociale</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>Code organisme</th>
                <th>Mutuelle</th>
            </tr>
            </thead>
            <tbody>
            {MedicalEventStore.patients.map((patient) => renderPatient(patient))}
            </tbody>
        </table>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, padding: 40}}>
        {onEditPatient ?
            <EditPatient patient={onEditPatient}
                         onSuccess={() => setOnEditPatient(null)}
                         onCancel={() => setOnEditPatient(null)}
            />
            : <AddPatient/>}
        {renderPatients()}
    </div>
})

export default ListPatients